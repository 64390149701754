import axios from 'axios';
import jwtDefaultConfig from './jwtDefaultConfig';

class JwtService {
    jwtConfig = { ...jwtDefaultConfig };

    isAlreadyFetchingAccessToken = false;

    subscribers = [];

    constructor() {
        axios.interceptors.request.use(
            (config) => {
                const accessToken = this.getToken();

                if (accessToken) {
                    config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`;
                }

                if (config.url.includes('update-profile')) {
                    config.headers['Content-Type'] = 'multipart/form-data';
                } else if (config.method === 'post' && config.url.replaceAll('/', '').endsWith('expense')) {
                    config.headers['Content-Type'] = 'multipart/form-data';
                } else {
                    config.headers['Content-Type'] = 'application/json';
                }
                config.headers['Access-Control-Allow-Origin'] = '*';
                return config;
            },
            (error) => {
                Promise.reject(error);
            }
        );
    }

    onAccessTokenFetched(accessToken) {
        this.subscribers = this.subscribers.filter((callback) => callback(accessToken));
    }

    addSubscriber(callback) {
        this.subscribers.push(callback);
    }

    setIsLogin(check) {
        localStorage.setItem(this.jwtConfig.storageIsLoginKeyName, check);
    }

    getIsLogin() {
        const isLogin = localStorage.getItem(this.jwtConfig.storageIsLoginKeyName);
        if (isLogin && (isLogin === true || isLogin === 'true')) return true;
        else return false;
    }

    removeIsLogin() {
        localStorage.removeItem(this.jwtConfig.storageIsLoginKeyName);
    }

    setToken(value) {
        localStorage.setItem(this.jwtConfig.storageTokenKeyName, value);
    }

    getToken() {
        return localStorage.getItem(this.jwtConfig.storageTokenKeyName);
    }

    removeToken() {
        localStorage.removeItem(this.jwtConfig.storageTokenKeyName);
    }

    setRefreshToken(value) {
        localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value);
    }

    getRefreshToken() {
        return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName);
    }

    removeRefreshtoken() {
        localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName);
    }

    setUser(value) {
        localStorage.setItem(this.jwtConfig.storageUserKeyName, JSON.stringify(value));
    }

    getUser() {
        return JSON.parse(localStorage.getItem(this.jwtConfig.storageUserKeyName));
    }

    removeUser() {
        localStorage.removeItem(this.jwtConfig.storageUserKeyName);
    }

    login(args) {
        return axios.post(this.jwtConfig.loginEndpoint, args);
    }

    updatePassword(args) {
        return axios.post(this.jwtConfig.updatePasswordEndpoint, args);
    }

    resetPassword(args) {
        return axios.post(this.jwtConfig.resetPasswordEndpoint, args);
    }

    logout() {
        return axios.post(this.jwtConfig.logoutEndpoint, {
            refreshToken: this.getRefreshToken()
        });
    }

    refreshToken() {
        return axios.post(this.jwtConfig.refreshEndpoint, {
            refreshToken: this.getRefreshToken()
        });
    }

    createIsp(payload) {
        return axios.post(this.jwtConfig.ispEndpoint, payload);
    }

    editIsp(id, payload) {
        return axios.patch(`${this.jwtConfig.ispEndpoint}/${id}`, payload);
    }

    updateProfile(args) {
        return axios.post(`${this.jwtConfig.updateProfileEndpoint}`, args);
    }

    getAllIsps() {
        return axios.get(this.jwtConfig.ispEndpoint);
    }

    getIspById(id) {
        return axios.get(`${this.jwtConfig.ispEndpoint}/${id}`);
    }

    createPackage(payload) {
        return axios.post(this.jwtConfig.packageEndpoint, payload);
    }

    getAllPackages(isp) {
        return axios.get(`${this.jwtConfig.packageEndpoint}/by-isp/${isp}`);
    }

    updatePackage(id, payload) {
        return axios.patch(`${this.jwtConfig.packageEndpoint}/${id}`, payload);
    }

    deletePackage(id) {
        return axios.delete(`${this.jwtConfig.packageEndpoint}/${isp}`);
    }

    createUser(payload) {
        return axios.post(this.jwtConfig.userEndpoint, payload);
    }

    editUser(id, payload) {
        return axios.patch(`${this.jwtConfig.userEndpoint}/${id}`, payload);
    }

    getAllUsers() {
        return axios.get(this.jwtConfig.userEndpoint);
    }

    getAutoCompleteUsers(userId) {
        return axios.post(`${this.jwtConfig.userEndpoint}/autocomplete`, { userId });
    }

    deleteUser(id) {
        return axios.delete(`${this.jwtConfig.userEndpoint}/${id}`);
    }

    createEntry(payload) {
        return axios.post(this.jwtConfig.entryEndpoint, payload);
    }

    updateEntry(id, payload) {
        return axios.patch(`${this.jwtConfig.entryEndpoint}/${id}`, payload);
    }

    deleteEntry(id) {
        return axios.delete(`${this.jwtConfig.entryEndpoint}/${id}`);
    }

    getEntryById(id) {
        return axios.get(`${this.jwtConfig.entryEndpoint}/${id}`);
    }

    getAllCompletedEntries(payload) {
        return axios.post(`${this.jwtConfig.entryEndpoint}/completed`, payload);
    }

    getAllPendingEntries() {
        return axios.get(`${this.jwtConfig.entryEndpoint}/pending`);
    }

    getAllPendingEntriesWithinDateRange(payload) {
        return axios.post(`${this.jwtConfig.entryEndpoint}/pending`, payload);
    }

    addStaff(payload) {
        return axios.post(this.jwtConfig.staffEndpoint, payload);
    }

    getAllStaffs() {
        return axios.get(this.jwtConfig.staffEndpoint);
    }

    getAllPartners() {
        return axios.get(`${this.jwtConfig.staffEndpoint}/getAllPartners`);
    }

    createInvoice(payload) {
        return axios.post(this.jwtConfig.invoiceEndpoint, payload);
    }

    getSentInvoices(payload) {
        return axios.post(`${this.jwtConfig.invoiceEndpoint}/sent-invoices`, payload);
    }

    getAllInvoices(payload) {
        return axios.post(`${this.jwtConfig.invoiceEndpoint}/all-invoices`, payload);
    }

    deleteInvoice(id) {
        return axios.delete(`${this.jwtConfig.invoiceEndpoint}/${id}`);
    }

    createExpense(payload) {
        return axios.post(this.jwtConfig.expenseEndpoint, payload);
    }

    getCompletedExpenses(payload) {
        return axios.post(`${this.jwtConfig.expenseEndpoint}/completed`, payload);
    }

    getPendingExpenses(payload) {
        return axios.post(`${this.jwtConfig.expenseEndpoint}/pending`, payload);
    }

    approveExpense(id) {
        return axios.get(`${this.jwtConfig.expenseEndpoint}/approve/${id}`);
    }

    deleteExpense(id) {
        return axios.delete(`${this.jwtConfig.expenseEndpoint}/${id}`);
    }

    reverseExpense(id) {
        return axios.get(`${this.jwtConfig.expenseEndpoint}/reverse/${id}`);
    }

    reviveExpense(id) {
        return axios.get(`${this.jwtConfig.expenseEndpoint}/revive/${id}`);
    }

    getSummary(payload) {
        return axios.post(this.jwtConfig.summaryEndpoint, payload);
    }

    sendServerDownAlert(id) {
        return axios.get(`${this.jwtConfig.ispEndpoint}/server-down-alert/${id}`);
    }

    sendServerUpAlert(id) {
        return axios.get(`${this.jwtConfig.ispEndpoint}/server-up-alert/${id}`);
    }

    sendCustomMessage(payload) {
        return axios.post(`${this.jwtConfig.userEndpoint}/send-custom-message`, payload);
    }

    updateSmsSending(payload) {
        return axios.post(this.jwtConfig.smsSendingEndpoint, payload);
    }

    getSmsSending() {
        return axios.get(this.jwtConfig.smsSendingEndpoint);
    }

    getSmsBalance() {
        return axios.get(`${this.jwtConfig.smsSendingEndpoint}/sms-balance`);
    }

    createExtraIncome(payload) {
        return axios.post(this.jwtConfig.extraIncomeEndpoint, payload);
    }

    getAllCompletedExtraIncome(payload) {
        return axios.post(`${this.jwtConfig.extraIncomeEndpoint}/completed`, payload);
    }

    getAllPendingExtraIncome(payload) {
        return axios.post(`${this.jwtConfig.extraIncomeEndpoint}/pending`, payload);
    }

    updateExtraIncome(id, payload) {
        return axios.patch(`${this.jwtConfig.extraIncomeEndpoint}/${id}`, payload);
    }

    deleteExtraIncome(id) {
        return axios.delete(`${this.jwtConfig.extraIncomeEndpoint}/${id}`);
    }
}

const jwt = new JwtService();
export default jwt;
