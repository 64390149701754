import jwt from 'jwtservice/jwtService';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { AppContextProvider } from './AppContext';

function AppContextContainer({ children }) {
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [filters, setFilters] = useState([]);
    const [smsBalance, setSmsBalance] = useState('0.0');

    useEffect(() => {
        // getSmsBalance();
    }, []);

    const getSmsBalance = async () => {
        jwt.getSmsBalance()
            .then((res) => {
                setSmsBalance((+res?.data?.smsBalance).toFixed(2));
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const contextValues = {
        data,
        setData,
        filteredData,
        setFilteredData,
        filters,
        setFilters,
        smsBalance,
        getSmsBalance
    };

    return <AppContextProvider value={contextValues}>{children}</AppContextProvider>;
}

export default AppContextContainer;
